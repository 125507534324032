<template lang="pug">
.form-review(:class="{'form-review-send': sendForm}")
  .form-review__block
    .form-review__fields
      .form-review__field
        .input-text.input-text-icon
          input.input-text__input(
            type="text"
            v-model="fieldName"
            :placeholder="formFieldNamePlaceholder"
          )
          svg.input-text__icon
            use(xlink:href="#icon-user")

      .form-review__field
        .input-text.input-text-icon
          input.input-text__input(
            type="text"
            v-model="fieldEmail"
            :placeholder="formFieldEmailPlaceholder"
          )
          svg.input-text__icon
            use(xlink:href="#contact-email")

    .form-review__text
      .input-textarea(:class="{'input-textarea-error': fieldError && v$.$invalid}")
        textarea.input-textarea__input(
          v-model="fieldText"
          :placeholder="formFieldTextPlaceholder"
        )

    .form-review__stars
      .form-review__stars-title Ваша оценка:
      .form-review__stars-block
        .stars.stars-review
          .stars__icons
            .stars__icon(
              v-for="star in [1, 2, 3, 4, 5]"
              :class="{'stars__icon-disable': parseInt(star) > parseInt(fieldStars)}"
              @click="getStars(star)"
            )

    .form-review__files
      .form-review__file
        .form-files(:class="filesClass")
          .form-files__items
            .form-files__item
              input(
                type="file"
                name="files"
                ref="files"
                id="fileForm1"
                multiple
                style="display: none;"
                @change="onFileChange"
              )
              label.form-files__label(
                v-html="formFieldFilesButton"
                for="fileForm1"
              )
            .form-files__item
              label.form-files__result(v-html="fileResult")

          .form-files__info(v-html="fileInfo")

    .form-review__consent
      label.consent
        input.consent__input(
          type="checkbox"
          value="consent"
          checked="checked"
          v-model="consent"
        )
        span.consent__button
        span.consent__label(v-html="formConsentText")

    .form-review__button
      .button.button-yellow(
        @click="getSend()"
      )
        .button__name(v-html="formButtonName")

  .form-review__success
    .form-success
      .form-success__title(v-html="formSuccessTitle")
      .form-success__text(v-html="formSuccessText")

</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'
export default {
  name: 'AppFormReview',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingTitle",
    "formSettingSubject",

    "formButtonName",

    "formFieldName",
    "formFieldNamePlaceholder",
    "formFieldEmail",
    "formFieldEmailPlaceholder",
    "formFieldText",
    "formFieldTextPlaceholder",
    "formFieldStars",
    "formFieldPage",

    "formSuccessTitle",
    "formSuccessText",

    "formConsentText",

    "formRedirect",
    "formPageId",
    "formPagePagetitle"
  ],

  data () {
    return {
      fieldName: '',
      fieldEmail: '',
      fieldText: '',
      fieldStars: 5,
      files: '',
      consent: ['consent'],

      formFieldFilesButton: 'Выберите файлы',
      fileResult: 'Файл не выбран',
      fileInfo: 'Не больше 3-х изображений',
      filesClass: '',

      sendForm: false,
      fieldError: false
    }
  },

  methods: {
    ...mapActions([
      'SendReview'
    ]),

    onFileChange () {
      this.files = this.$refs.files.files

      if (this.files.length > 3) {
        this.fileResult = 'Превышено кол-во изображений'
        this.filesClass = 'form-files-error'

        setTimeout(() => {
          this.fileResult = 'Файл не выбран'
          this.filesClass = ''
        }, 5000)
      } else {
        if (this.files.length === 1) {
          this.fileResult = 'Добавлен ' + this.files.length + ' файл'
        } else if ((this.files.length === 2) || (this.files.length === 3) || (this.files.length === 4)) {
          this.fileResult = 'Добавлено ' + this.files.length + ' файла'
        } else {
          this.fileResult = 'Добавлено ' + this.files.length + ' файлов'
        }

        this.filesClass = ''
      }
    },

    getStars (star) {
      this.fieldStars = star
    },

    getSend () {
      if (this.v$.$invalid) {
        this.fieldError = true
      }

      if (!this.v$.$invalid) {
        // this.sendForm = !this.sendForm

        this.SendReview({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              name: 'name',
              title: this.formFieldName,
              value: this.fieldName
            },
            {
              name: 'email',
              title: this.formFieldEmail,
              value: this.fieldEmail
            },
            {
              name: 'stars',
              title: this.formFieldStars,
              value: this.fieldStars
            },
            {
              name: 'text',
              title: this.formFieldText,
              value: this.fieldText
            },
            {
              title: this.formFieldPage,
              value: this.formPagePagetitle + ' (' + this.formPageId + ')'
            },
          ],
          files: this.files,
          redirectPage: this.formRedirect
        })

        this.fieldName = this.fieldEmail = this.fieldText = ''

        /*
        setTimeout(() => {
          // this.sendForm = !this.sendForm
          // window.location.href = this.formRedirect
        }, 2500)
        */
      }
    }
  },

  validations () {
    return {
      fieldText: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(600)
      },

      consent: {
        required
      }
    }
  }
}
</script>
