<template lang="pug">
.form(:class="{'form-send': sendForm}")
  .form__block.form__block-price(v-if="formName === 'price'")
    .form__fields
      .input-text.input-text-icon(:class="{'input-text-error': fieldError && v$.$invalid}")
        input.input-text__input(
          type="tel"
          v-model="fieldPhone"
          :placeholder="formFieldPhonePlaceholder"
          v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
        )
        svg.input-text__icon
          use(xlink:href="#icon-phone")

    .form__wh
      label.consent-wh.consent-wh---gray
        input.consent-wh__input(
          type="checkbox"
          value="wh"
          v-model="fieldWh"
        )
        span.consent-wh__button
        span.consent-wh__label(v-html="formWhatsappText")
        svg.consent-wh__icon
          use(xlink:href="#social-wt")

    .form__button
      .button.button-yellow.button-width(
        @click="getSend()"
      )
        .button__name(v-html="formButtonName")

    .form__consent
      label.consent
        input.consent__input(
          type="checkbox"
          value="consent"
          checked="checked"
          v-model="consent"
        )
        span.consent__button
        span.consent__label(v-html="formConsentText")


  .form__block.form__block-up(v-if="formName === 'up'")
    .form__fields
      .input-text.input-text-icon(:class="{'input-text-error': fieldError && v$.$invalid}")
        input.input-text__input(
          type="tel"
          v-model="fieldPhone"
          :placeholder="formFieldPhonePlaceholder"
          v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
        )
        svg.input-text__icon
          use(xlink:href="#icon-phone")

      .button.button-yellow.button-width(
        @click="getSend()"
      )
        .button__name(v-html="formButtonName")

    .form__bottom
      .form__consent
        label.consent.consent-white
          input.consent__input(
            type="checkbox"
            value="consent"
            checked="checked"
            v-model="consent"
          )
          span.consent__button
          span.consent__label(v-html="formConsentText")

      .form__wh
        label.consent-wh.consent-wh---white
          input.consent-wh__input(
            type="checkbox"
            value="wh"
            v-model="fieldWh"
          )
          span.consent-wh__button
          span.consent-wh__label(v-html="formWhatsappText")
          svg.consent-wh__icon
            use(xlink:href="#social-wt")

  .form__block.form__block-popup(v-if="(formName === 'popup') || (formName === 'product')")
    h3.center(v-html="formTitle")

    .form__fields
      .input-text.input-text-icon
        input.input-text__input(
          type="text"
          v-model="fieldName"
          :placeholder="formFieldNamePlaceholder"
        )
        svg.input-text__icon
          use(xlink:href="#icon-user")

      .input-text.input-text-icon(:class="{'input-text-error': fieldError && v$.$invalid}")
        input.input-text__input(
          type="tel"
          v-model="fieldPhone"
          :placeholder="formFieldPhonePlaceholder"
          v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
        )
        svg.input-text__icon
          use(xlink:href="#icon-phone")

    .form__wh
      label.consent-wh.consent-wh---gray
        input.consent-wh__input(
          type="checkbox"
          value="wh"
          v-model="fieldWh"
        )
        span.consent-wh__button
        span.consent-wh__label(v-html="formWhatsappText")
        svg.consent-wh__icon
          use(xlink:href="#social-wt")

    .form__consent
      label.consent
        input.consent__input(
          type="checkbox"
          value="consent"
          checked="checked"
          v-model="consent"
        )
        span.consent__button
        span.consent__label(v-html="formConsentText")

    .button.button-yellow.button-width(
      @click="getSend()"
    )
      .button__name(v-html="formButtonName")

  .form__success
    .form-success(:class="'form-success-' + formName")
      .form-success__title(v-html="formSuccessTitle")
      .form-success__text(v-html="formSuccessText")

</template>

<script>
/*global $*/
/*eslint no-undef: "error"*/
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'
export default {
  name: 'AppForm',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formName",

    "formSettingTitle",
    "formSettingSubject",

    "formTitle",
    "formButtonName",

    "formFieldName",
    "formFieldNamePlaceholder",
    "formFieldPhone",
    "formFieldPhonePlaceholder",
    "formFieldPage",

    "formSuccessTitle",
    "formSuccessText",

    "formConsentText",

    "formWhatsappText",
    "formWhatsappValue",

    "formRedirect",
    "formPageId",
    "formPagePagetitle"
  ],

  data () {
    return {
      fieldName: '',
      fieldPhone: '',
      fieldWh: [],
      files: '',
      consent: ['consent'],
      sendForm: false,
      fieldError: false
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (this.v$.$invalid) {
        this.fieldError = true
      }

      if (!this.v$.$invalid) {
        // this.sendForm = !this.sendForm

        if ((this.formName === 'price') || (this.formName === 'up')) {
          this.Send({
            setting: {
              title: this.formSettingTitle,
              subject: this.formSettingSubject
            },

            fields: [
              {
                title: this.formFieldPhone,
                value: this.fieldPhone
              },
              {
                title: this.formWhatsappText,
                value: (this.fieldWh.length)? this.formWhatsappValue: ""
              },
              {
                title: this.formFieldPage,
                value: this.formPagePagetitle + ' (' + this.formPageId + ')'
              }
            ],
            files: this.files,
            redirectPage: this.formRedirect
          })

          this.fieldPhone = ''
        }

        if (this.formName === 'popup') {
          this.Send({
            setting: {
              title: this.formSettingTitle,
              subject: this.formSettingSubject
            },

            fields: [
              {
                title: this.formFieldName,
                value: this.fieldName
              },
              {
                title: this.formFieldPhone,
                value: this.fieldPhone
              },
              {
                title: this.formWhatsappText,
                value: (this.fieldWh.length)? this.formWhatsappValue: ""
              },
              {
                title: this.formFieldPage,
                value: this.formPagePagetitle + ' (' + this.formPageId + ')'
              },
            ],
            files: this.files,
            redirectPage: this.formRedirect
          })

          this.fieldName = this.fieldPhone = ''
        }

        if (this.formName === 'product') {
          this.Send({
            setting: {
              title: this.formSettingTitle,
              subject: this.formSettingSubject
            },

            fields: [
              {
                title: this.formFieldName,
                value: this.fieldName
              },
              {
                title: this.formFieldPhone,
                value: this.fieldPhone
              },
              {
                title: this.formFieldPage,
                value: $(`.button-product--button-active`).data('product-name') + ' (' + $(`.button-product--button-active`).data('product-id') + ')'
              },
            ],
            files: this.files,
            redirectPage: this.formRedirect
          })

          this.fieldName = this.fieldPhone = ''
        }

        /*
        setTimeout(() => {
          // this.sendForm = !this.sendForm
          // window.location.href = this.formRedirect
        }, 2500)
       */
      }
    }
  },

  validations () {
    return {
      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },

      consent: {
        required
      }
    }
  }
}
</script>
