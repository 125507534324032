<template lang="pug">
.calc(:class="{'calc-send': sendForm, 'calc-page': formType === 'page'}")
  .calc__block
    .calc__fields.calc__fields-top
      .calc__field
        .calc-field(:class="{'calc-field-black': formType === 'page'}")
          .calc-field__title(v-html="formField1Title")
          .calc-field__block
            .calc-field__value.calc-field__value-input
              input.calc-field__input(
                type="number"
                v-model.lazy.trim.number="field1"
              )
              span.metr(v-html="formField1Metr")

            .calc-field__buttons
              .calc-field__button(@click="getField('field1', 'plus')")
                svg.calc-field__icon
                  use(xlink:href="#icon-arrow")
              .calc-field__button(@click="getField('field1', 'minus')")
                svg.calc-field__icon
                  use(xlink:href="#icon-arrow")

      .calc__field
        .calc-field(:class="{'calc-field-black': formType === 'page'}")
          .calc-field__title(v-html="formField2Title")
          .calc-field__block
            .calc-field__value.calc-field__value-input
              input.calc-field__input(
                type="number"
                v-model.lazy.trim.number="field2"
              )
              span.metr(v-html="formField2Metr")

            .calc-field__buttons
              .calc-field__button(@click="getField('field2', 'plus')")
                svg.calc-field__icon
                  use(xlink:href="#icon-arrow")
              .calc-field__button(@click="getField('field2', 'minus')")
                svg.calc-field__icon
                  use(xlink:href="#icon-arrow")

      .calc__field
        .calc-field(:class="{'calc-field-black': formType === 'page'}")
          .calc-field__title(v-html="formField3Title")
          .calc-field__block
            .calc-field__value.calc-field__value-input
              input.calc-field__input(
                type="number"
                v-model.lazy.trim.number="field3"
              )
              span.metr(v-html="formField3Metr")

            .calc-field__buttons
              .calc-field__button(@click="getField('field3', 'plus')")
                svg.calc-field__icon
                  use(xlink:href="#icon-arrow")
              .calc-field__button(@click="getField('field3', 'minus')")
                svg.calc-field__icon
                  use(xlink:href="#icon-arrow")

    .calc__fields.calc__fields-bottom
      .calc__field
        .calc-field.calc-field-select(:class="{'calc-field-open': field4Open, 'calc-field-black': formType === 'page'}")
          .calc-field__title(v-html="formField4Title")
          .calc-field__block(@click="toggleField4()" v-click-outside="() => field4Open = false")
            .calc-field__value <span class="value">{{field4}}</span>
            .calc-field__buttons
              .calc-field__button
                svg.calc-field__icon
                  use(xlink:href="#icon-arrow")

        .calc__select(:class="{'calc__select-open': field4Open}")
          .calc-select
            .calc-select__item(v-for="item in field4List" @click="activeField4(item[0], item[1])")
              .calc-select__text(v-html="item[0]")

      .calc__field
        .input-text.input-text-icon(:class="{'input-text-black': formType === 'page', 'input-text-error': fieldError && v$.$invalid}")
          .input-text__title(v-html="formFieldPhoneTitle")
          input.input-text__input(
            type="tel"
            v-model="fieldPhone"
            :placeholder="formFieldPhonePlaceholder"
            v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
          )
          svg.input-text__icon
            use(xlink:href="#icon-phone")

    .calc__sale
      .calc-sale(:class="{'calc-sale-page': formType === 'page'}")
        .calc-sale__top
          .calc-sale__title(v-html="formSaleTitle")

          .calc-sale__fields
            .calc-sale__field(v-for="item in fieldSaleFields" :class="{'calc-sale__field-active': item[1] === fieldSaleActive}" v-html="item[0]" @click="getSaleActive(item[0], item[1])")

        .calc-sale__text(v-html="formSaleText")

    .calc__total
      .calc__total-title(v-html="formTotalTitle")
      .calc__total-old <span>{{totalPriceOld}}</span> руб.
      .calc__total-value <span>{{totalPrice}}</span> руб.

    .calc__form
      .calc__wh
        label.consent-wh(:class="{'consent-wh---white': formType !== 'page', 'consent-wh---gray': formType === 'page'}")
          input.consent-wh__input(
            type="checkbox"
            value="wh"
            v-model="fieldWh"
          )
          span.consent-wh__button
          span.consent-wh__label(v-html="formWhatsappText")
          svg.consent-wh__icon
            use(xlink:href="#social-wt")

      .calc__consent
        label.consent(:class="{'consent-white': formType !== 'page'}")
          input.consent__input(
            type="checkbox"
            value="consent"
            checked="checked"
            v-model="consent"
          )
          span.consent__button
          span.consent__label(v-html="formConsentText")

      .calc__button
        .button.button-yellow.button-width(@click="getSend()")
          .button__name(v-html="formButtonName")

  .calc__success
    .form-success.form-success-center(:class="{'form-success-white': formType !== 'page'}")
      .form-success__title(v-html="formSuccessTitle")
      .form-success__text(v-html="formSuccessText")

</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'
export default {
  name: 'AppCalc',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formType",

    "formSettingTitle",
    "formSettingSubject",

    "formField1",
    "formField1Title",
    "formField1Value",
    "formField1Metr",
    "formField1Min",
    "formField1Max",

    "formField2",
    "formField2Title",
    "formField2Value",
    "formField2Metr",
    "formField2Price",
    "formField2Min",
    "formField2Max",

    "formField3",
    "formField3Title",
    "formField3Value",
    "formField3Metr",
    "formField3Price",
    "formField3Min",
    "formField3Max",

    "formField4",
    "formField4Title",
    "formField4Value",
    "formField4Price",
    "formField4List",

    "formSaleTitle",
    "formSaleList",
    "formSaleText",

    "formFieldPhone",
    "formFieldPhoneTitle",
    "formFieldPhonePlaceholder",
    "formFieldPage",

    "formTotalTitle",
    "formSale",
    "formTotal",
    "formButtonName",

    "formSuccessTitle",
    "formSuccessText",

    "formConsentText",

    "formWhatsappText",
    "formWhatsappValue",

    "formRedirect",
    "formPageId",
    "formPagePagetitle"
  ],

  data () {
    return {
      field1: this.formField1Value,
      field1Min: this.formField1Min,
      field1Max: this.formField1Max,
      field2: this.formField2Value,
      field2Price: this.formField2Price,
      field2Min: this.formField2Min,
      field2Max: this.formField2Max,
      field3: this.formField3Value,
      field3Price: this.formField3Price,
      field3Min: this.formField3Min,
      field3Max: this.formField3Max,
      field4: this.formField4Value,
      field4Price: this.formField4Price,
      field4List: this.formField4List,
      field4Open: false,
      fieldSaleFields: this.formSaleList,
      fieldSaleActive: 1,
      fieldSaleActiveName: '',

      fieldPhone: '',
      fieldWh: [],
      files: '',
      consent: ['consent'],
      sendForm: false,
      fieldError: false
    }
  },

  computed: {
    totalPriceOld () {
      return parseInt((parseInt(this.field1) * parseInt(this.field4Price)) + (parseInt(this.field2) * parseInt(this.field2Price)) + (Math.sqrt(parseInt(this.field1)) * 4 * 300) + (parseInt(this.field3) * 950))
    },

    totalPrice () {
      let curSale = 1

      if (parseInt(this.fieldSaleActive) === 1) {
        curSale = 0.8
      }

      if (parseInt(this.fieldSaleActive) === 2) {
        curSale = 0.7
      }

      if (parseInt(this.fieldSaleActive) === 3) {
        curSale = 0.6
      }

      return parseInt(this.totalPriceOld * curSale)
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSaleActive (name, type) {
      this.fieldSaleActive = type
      this.fieldSaleActiveName = name
    },

    getField (name, type) {
      if (name === 'field1') {
        if (type === 'plus') {
          if ((parseInt(this.field1) + 1) <= parseInt(this.field1Max)) {
            this.field1 = parseInt(this.field1) + 1
          }
        } else {
          if ((parseInt(this.field1) - 1) >= parseInt(this.field1Min)) {
            this.field1 = parseInt(this.field1) - 1
          }
        }
      }

      if (name === 'field2') {
        if (type === 'plus') {
          if ((parseInt(this.field2) + 1) <= parseInt(this.field2Max)) {
            this.field2 = parseInt(this.field2) + 1
          }
        } else {
          if ((parseInt(this.field2) - 1) >= parseInt(this.field2Min)) {
            this.field2 = parseInt(this.field2) - 1
          }
        }
      }

      if (name === 'field3') {
        if (type === 'plus') {
          if ((parseInt(this.field3) + 1) <= parseInt(this.field3Max)) {
            this.field3 = parseInt(this.field3) + 1
          }
        } else {
          if ((parseInt(this.field3) - 1) >= parseInt(this.field3Min)) {
            this.field3 = parseInt(this.field3) - 1
          }
        }
      }
    },

    toggleField4 () {
      this.field4Open = !this.field4Open
    },

    activeField4 (name, price) {
      this.field4Open = false
      this.field4 = name
      this.field4Price = price
    },

    getSend () {
      if (this.v$.$invalid) {
        this.fieldError = true
      }

      if (!this.v$.$invalid) {
        // this.sendForm = !this.sendForm

        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: this.formFieldPhone,
              value: this.fieldPhone
            },
            {
              title: this.formField1,
              value: this.field1
            },
            {
              title: this.formField2,
              value: this.field2
            },
            {
              title: this.formField3,
              value: this.field3
            },
            {
              title: this.formField4,
              value: this.field4
            },
            {
              title: this.formSale,
              value: this.fieldSaleActiveName
            },
            {
              title: this.formTotal,
              value: this.totalPrice
            },
            {
              title: this.formWhatsappText,
              value: (this.fieldWh.length)? this.formWhatsappValue: ""
            },
            {
              title: this.formFieldPage,
              value: this.formPagePagetitle + ' (' + this.formPageId + ')'
            },
          ],
          files: this.files,
          redirectPage: this.formRedirect
        })

        this.fieldPhone = ''

        /*
        setTimeout(() => {
          this.sendForm = !this.sendForm
        }, 3500)
       */
      }
    }
  },

  validations () {
    return {
      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },

      consent: {
        required
      }
    }
  },

  watch: {
    field1(newValue) {
      let curNewValue = parseInt(newValue)

      if ((curNewValue < 1) || (!curNewValue)) {
        this.field1 = 1
      }

      if (curNewValue > 999) {
        this.field1 = 999
      }
    },

    field2(newValue) {
      let curNewValue = parseInt(newValue)

      if ((curNewValue < 1) || (!curNewValue)) {
        this.field2 = 1
      }

      if (curNewValue > 999) {
        this.field2 = 999
      }
    },

    field3(newValue) {
      let curNewValue = parseInt(newValue)

      if ((curNewValue < 1) || (!curNewValue)) {
        this.field3 = 1
      }

      if (curNewValue > 999) {
        this.field3 = 999
      }
    }
  },

  created () {
    let list = this.field4List.split('|')
    let result = []

    for (let n = 0; n < list.length; n++) {
      result.push(list[n].split(':'))
    }

    this.field4List = result


    let list2 = this.fieldSaleFields.split('|')
    let result2 = []

    for (let n = 0; n < list2.length; n++) {
      result2.push(list2[n].split(':'))
    }

    this.fieldSaleFields = result2
    this.fieldSaleActive = result2[0][1]
    this.fieldSaleActiveName = result2[0][0]
  },


  directives: {
    clickOutside: {
      mounted(element, {value}) {
        element.clickOutside = function (event) {
          if (!(element === event.target || element.contains(event.target))) {
            value(event)
          }
        }

        document.body.addEventListener('click', element.clickOutside)
      },

      unmounted(element) {
        document.body.removeEventListener('click', element.clickOutside)
      }
    }
  }
}
</script>
